import {Button, Card, CardHeader, Link} from "@nextui-org/react";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa6";
import {useState} from "react";
import Swipe from "react-easy-swipe";

import { NEWS_TYPE } from '@/components/helpers/constant.js';
import SharedService from "@/services/shared-service";

var _ = require('lodash');

function DossilContent(dossil) {
    const cateParentCode = _.head(dossil.data.categories)?.code || 'post';

    return <>
        <div className="text-center font-semibold">
            <p>{dossil.data.authorName}</p>
        </div>

        <Link href={SharedService.getPostPath(dossil)} className="block">
            <div className="text-white text-justify sm:text-center mt-5 mx-5">
                <span className="inline-block" dangerouslySetInnerHTML={{__html: dossil.data.metaDescription}}></span>
            </div>
            <div className="text-secondary text-center my-10">
                <span className="inline-block text-xl">{dossil.data.title}</span>
            </div>
        </Link>
    </>
}

export default function Dossil(dossilsData) {
    const dossils = dossilsData.data;

    const [currentSlide, setCurrentSlide] = useState(0);

    // Actions Slide
    const handleNextSlide = () => {
        let newSlide = currentSlide === dossils.value.length - 1 ? 0 : currentSlide + 1;
        setCurrentSlide(newSlide);
    };
    const handlePrevSlide = () => {
        let newSlide = currentSlide === 0 ? dossils.value.length - 1 : currentSlide - 1;
        setCurrentSlide(newSlide);
    };

    return (
        (dossils.total > 0) &&
        <div className="my-5">
            <Card className="rounded-[5px] bg-gray-800/50 !bg-cover !bg-center !bg-[url('/webp/book.webp')] !bg-blend-darken">
                <CardHeader className="justify-center">
                    <div className="flex justify-between items-center">
                        <Button className="ml-5 md:ml-0 bg-primary" radius="full" isIconOnly size="sm" aria-label="Previous">
                            <FaChevronLeft className="fill-white h-[32px] w-[32px] p-1" onClick={handlePrevSlide}/>
                        </Button>
                        <div className="mx-10 text-2xl font-bold">
                            <Link href={`/chuyen-de/${NEWS_TYPE.DOCUMENT_LIST.code}`} className="md:mx-10 text-xl md:text-2xl font-bold text-white">
                                { NEWS_TYPE.DOCUMENT_LIST.name.toLocaleUpperCase() }
                            </Link>
                        </div>
                        <Button className="mr-5 md:mr-0 bg-primary" radius="full" isIconOnly size="sm" aria-label="Next">
                            <FaChevronRight className="fill-white h-[32px] w-[32px] p-1" onClick={handleNextSlide}/>
                        </Button>
                    </div>
                </CardHeader>
                
                <Swipe className=""
                    onSwipeLeft={handleNextSlide}
                    onSwipeRight={handlePrevSlide}
                >
                    {dossils.value.map((dossil, index) => {
                        if (index === currentSlide) {
                            return (<>
                                    <div className="">
                                        <DossilContent data={dossil} />
                                    </div>
                                </>
                            );
                        }}
                    )}
                </Swipe>
            </Card>
        </div>
    )
}